export interface configInterface {
  backendUrl: string;
  backendUrlClean: string;
  API_URL?: string;
  layout: ILayout;
  username: string;
  password: string;
  googleKey: string;
  googleSecret: string;
}

export interface ILayout {
  layoutType: LayoutTypes;
  layoutColor: LayoutColor;
  layoutWidth: LayoutWidth;
  menuPosition: MenuPositions;
  leftSideBarTheme: SideBarTheme;
  leftSideBarType: SideBarTypes;
  topbarTheme: TopbarTheme;
}

export enum LayoutTypes {
  LAYOUT_VERTICAL = "vertical",
  LAYOUT_HORIZONTAL = "horizontal",
  LAYOUT_DETACHED = "detached",
  LAYOUT_TWO_COLUMN = "two-column",
}

export enum LayoutColor {
  LAYOUT_COLOR_LIGHT = "light",
  LAYOUT_COLOR_DARK = "dark",
}

export enum LayoutWidth {
  LAYOUT_WIDTH_FLUID = "fluid",
  LAYOUT_WIDTH_BOXED = "boxed",
}

export enum MenuPositions {
  MENU_POSITION_FIXED = "fixed",
  MENU_POSITION_SCROLLABLE = "scrollable",
}

export enum SideBarTheme {
  LEFT_SIDEBAR_THEME_LIGHT = "light",
  LEFT_SIDEBAR_THEME_DARK = "dark",
  LEFT_SIDEBAR_THEME_BRAND = "brand",
  LEFT_SIDEBAR_THEME_GRADIENT = "gradient",
}

export enum SideBarTypes {
  LEFT_SIDEBAR_TYPE_DEFAULT = "default",
  LEFT_SIDEBAR_TYPE_CONDENSED = "condensed",
  LEFT_SIDEBAR_TYPE_COMPACT = "compact",
}

export enum TopbarTheme {
  TOPBAR_THEME_LIGHT = "light",
  TOPBAR_THEME_DARK = "dark",
}

export const config: configInterface = {
  backendUrl: "https://backend-dev.servi.co/graphql",
  // backendUrl: "http://localhost:8000/graphql",
  backendUrlClean: "https://backend-dev.servi.co",
  // backendUrlClean: "http://localhost:8000",
  googleKey: "6LeUeggqAAAAAJ8WmCfnidUGq6my4pm-aTkHqVXJ",
  googleSecret: "6LeUeggqAAAAACIjvffE3LC2UtIyVVRe48MrWoFU",
  username: "admin",
  password: "iVErtLeVEraY",
  API_URL: process.env.REACT_APP_API_URL,
  layout: {
    layoutColor: LayoutColor.LAYOUT_COLOR_LIGHT,
    layoutType: LayoutTypes.LAYOUT_TWO_COLUMN,
    layoutWidth: LayoutWidth.LAYOUT_WIDTH_FLUID,
    leftSideBarTheme: SideBarTheme.LEFT_SIDEBAR_THEME_LIGHT,
    leftSideBarType: SideBarTypes.LEFT_SIDEBAR_TYPE_DEFAULT,
    menuPosition: MenuPositions.MENU_POSITION_FIXED,
    topbarTheme: TopbarTheme.TOPBAR_THEME_LIGHT,
  },
};

export default config;
