export const ReviewPageResourcesEN: IReviewPageResources = {
  title: "Review Page",
};

export const ReviewPageResourcesDE: IReviewPageResources = {
  title: "Bewertungsseite",
};

export interface IReviewPageResources {
  title: string;
}
