export const MessagesPageResourcesEN: IMessagesPageResources = {
  title: "My Messages",
};

export const MessagesPageResourcesDE: IMessagesPageResources = {
  title: "Meine Nachrichten",
};

export interface IMessagesPageResources {
  title: string;
}
