// apicore

import { APICore, setAuthorization } from "../../helpers/api/apiCore";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { authApiResponseError, authApiResponseSuccess } from "./actions";

import { AuthActionTypes } from "./constants";
import { IUserData } from "../interfaces";
import { SagaIterator } from "@redux-saga/core";
import { forgotPassword as forgotPasswordApi } from "../../helpers/";

interface UserData {
  payload: IUserData;
  type: string;
}

const api = new APICore();

/**
 * Login the user
 * @param {*} payload - email and password
 */
function* login({ payload, type }: UserData): SagaIterator {
  try {
    const user = payload;
    localStorage.setItem("user", JSON.stringify(user));
    yield put(authApiResponseSuccess(AuthActionTypes.LOGIN_USER, user));
  } catch (error: any) {
    yield put(authApiResponseError(AuthActionTypes.LOGIN_USER, error));
    api.setLoggedInUser(null);
    setAuthorization(null);
  }
}

/**
 * Logout the user
 */
function* logout(): SagaIterator {
  try {
    localStorage.removeItem("user");
    yield put(authApiResponseSuccess(AuthActionTypes.LOGOUT_USER, {}));
  } catch (error: any) {
    yield put(authApiResponseError(AuthActionTypes.LOGOUT_USER, error));
  }
}

// function* signup({
//   payload: { fullName, email, password },
// }: UserData): SagaIterator {
//   try {
//     // const response = yield call(signupApi, { fullName, email, password });
//     // const user = response.data;
//     // api.setLoggedInUser(user);
//     // setAuthorization(user['token']);
//     yield put(authApiResponseSuccess(AuthActionTypes.SIGNUP_USER, user));
//   } catch (error: any) {
//     yield put(authApiResponseError(AuthActionTypes.SIGNUP_USER, error));
//     api.setLoggedInUser(null);
//     setAuthorization(null);
//   }
// }

function* forgotPassword({ payload: { email } }: UserData): SagaIterator {
  try {
    const response = yield call(forgotPasswordApi, { email });
    yield put(
      authApiResponseSuccess(AuthActionTypes.FORGOT_PASSWORD, response.data)
    );
  } catch (error: any) {
    yield put(authApiResponseError(AuthActionTypes.FORGOT_PASSWORD, error));
  }
}
export function* watchLoginUser() {
  yield takeEvery(AuthActionTypes.LOGIN_USER, login);
}

export function* watchLogout() {
  yield takeEvery(AuthActionTypes.LOGOUT_USER, logout);
}

// export function* watchSignup(): any {
//   yield takeEvery(AuthActionTypes.SIGNUP_USER, signup);
// }

export function* watchForgotPassword(): any {
  yield takeEvery(AuthActionTypes.FORGOT_PASSWORD, forgotPassword);
}

function* authSaga() {
  yield all([
    fork(watchLoginUser),
    fork(watchLogout),
    // fork(watchSignup),
    fork(watchForgotPassword),
  ]);
}

export default authSaga;
