import { EnumOfferCategory } from "../../../redux/interfaces";

export const CategoriesResourcesEN: CategoriesResourcesInterface = {
  categories: [
    {
      label: "House and Office Help",
      options: [
        { value: EnumOfferCategory.HouseCleaning, label: "House Cleaning" },
        {
          value: EnumOfferCategory.ApartmentCleaning,
          label: "Apartment Cleaning",
        },
        { value: EnumOfferCategory.OfficeCleaning, label: "Office Cleaning" },
        { value: EnumOfferCategory.Ironing, label: "Ironing" },
        {
          value: EnumOfferCategory.HouseOfficeHelpOther,
          label: "Other House and Office Help",
        },
      ],
    },
    {
      label: "House Works",
      options: [
        { value: EnumOfferCategory.Painting, label: "Painting" },
        {
          value: EnumOfferCategory.ElectricalService,
          label: "Electrical Service",
        },
        {
          value: EnumOfferCategory.ElectricalAppliances,
          label: "Electrical Appliances",
        },
        {
          value: EnumOfferCategory.FittingAssemblingDissembling,
          label: "Fitting – Assembling - Dissembling",
        },
        {
          value: EnumOfferCategory.BathroomSanitary,
          label: "Bathroom and Sanitary",
        },
        { value: EnumOfferCategory.Plumbing, label: "Plumbing" },
        { value: EnumOfferCategory.CeramicWorks, label: "Ceramic Works" },
        { value: EnumOfferCategory.GroundWork, label: "Ground Work" },
        {
          value: EnumOfferCategory.HouseWorksOther,
          label: "Other House Works",
        },
      ],
    },
    {
      label: "Garden and Landscape Design",
      options: [
        { value: EnumOfferCategory.Mowing, label: "Mowing" },
        { value: EnumOfferCategory.Trimming, label: "Trimming" },
        { value: EnumOfferCategory.RootMiling, label: "Root Miling" },
        {
          value: EnumOfferCategory.FenceConstruction,
          label: "Fence Construction",
        },
        { value: EnumOfferCategory.Earthworks, label: "Earthworks" },
        { value: EnumOfferCategory.GardenCleaning, label: "Cleaning" },
        { value: EnumOfferCategory.GardenRenovation, label: "Renovation" },
        {
          value: EnumOfferCategory.GardenLandscapeDesignOther,
          label: "Other Garden and Landscape Design",
        },
      ],
    },
    {
      label: "Animal Care",
      options: [
        { value: EnumOfferCategory.DogCare, label: "Dog Care" },
        { value: EnumOfferCategory.CatCare, label: "Cat Care" },
        { value: EnumOfferCategory.AnimalCareOther, label: "Other Animals" },
      ],
    },
    {
      label: "Moving and Transportation",
      options: [
        { value: EnumOfferCategory.StuffMoving, label: "Stuff Moving" },
        { value: EnumOfferCategory.PeopleTransport, label: "People Transport" },
        {
          value: EnumOfferCategory.MovingTransportationOther,
          label: "Other Moving and Transportation",
        },
      ],
    },
    {
      label: "Renovation",
      options: [
        { value: EnumOfferCategory.HouseRenovation, label: "House Renovation" },
        {
          value: EnumOfferCategory.ApartmentRenovation,
          label: "Apartment Renovation",
        },
        {
          value: EnumOfferCategory.OfficeRenovation,
          label: "Office Renovation",
        },
        { value: EnumOfferCategory.RenovationOther, label: "Other Renovation" },
      ],
    },
    {
      label: "Beauty",
      options: [
        { value: EnumOfferCategory.Makeup, label: "Makeup" },
        {
          value: EnumOfferCategory.ManicurePedicure,
          label: "Manicure and Pedicure",
        },
        { value: EnumOfferCategory.BeautyOther, label: "Other Beauty" },
      ],
    },
    {
      label: "Tutoring",
      options: [{ value: EnumOfferCategory.Tutoring, label: "Tutoring" }],
    },
    {
      label: "Other",
      options: [{ value: EnumOfferCategory.Other, label: "Other" }],
    },
  ],
};

export const CategoriesResourcesDE: CategoriesResourcesInterface = {
  categories: [
    {
      label: "Haushalts- und Bürohilfe",
      options: [
        { value: EnumOfferCategory.HouseCleaning, label: "Hausreinigung" },
        {
          value: EnumOfferCategory.ApartmentCleaning,
          label: "Wohnungsreinigung",
        },
        { value: EnumOfferCategory.OfficeCleaning, label: "Büroreinigung" },
        { value: EnumOfferCategory.Ironing, label: "Bügeln" },
        {
          value: EnumOfferCategory.HouseOfficeHelpOther,
          label: "Andere Haushalts- und Bürohilfe",
        },
      ],
    },
    {
      label: "Hausarbeiten",
      options: [
        { value: EnumOfferCategory.Painting, label: "Malerarbeiten" },
        {
          value: EnumOfferCategory.ElectricalService,
          label: "Elektroarbeiten",
        },
        {
          value: EnumOfferCategory.ElectricalAppliances,
          label: "Elektrogeräte",
        },
        {
          value: EnumOfferCategory.FittingAssemblingDissembling,
          label: "Auf-Abbau und Montage",
        },
        { value: EnumOfferCategory.BathroomSanitary, label: "Bad und Sanitär" },
        { value: EnumOfferCategory.Plumbing, label: "Klempnerarbeiten" },
        { value: EnumOfferCategory.CeramicWorks, label: "Keramikarbeiten" },
        { value: EnumOfferCategory.GroundWork, label: "Bodenarbeiten" },
        {
          value: EnumOfferCategory.HouseWorksOther,
          label: "Andere Hausarbeiten",
        },
      ],
    },
    {
      label: "Garten- und Landschaftsgestaltung",
      options: [
        { value: EnumOfferCategory.Mowing, label: "Mähen" },
        { value: EnumOfferCategory.Trimming, label: "Trimmen" },
        { value: EnumOfferCategory.RootMiling, label: "Wurzelfräsen" },
        { value: EnumOfferCategory.FenceConstruction, label: "Zaunbau" },
        { value: EnumOfferCategory.Earthworks, label: "Erdarbeiten" },
        { value: EnumOfferCategory.GardenCleaning, label: "Reinigung" },
        { value: EnumOfferCategory.GardenRenovation, label: "Renovierung" },
        {
          value: EnumOfferCategory.GardenLandscapeDesignOther,
          label: "Andere Garten- und Landschaftsgestaltung",
        },
      ],
    },
    {
      label: "Tierbetreuung",
      options: [
        { value: EnumOfferCategory.DogCare, label: "Hundebetreuung" },
        { value: EnumOfferCategory.CatCare, label: "Katzenbetreuung" },
        { value: EnumOfferCategory.AnimalCareOther, label: "Andere Tiere" },
      ],
    },
    {
      label: "Umzug und Transport",
      options: [
        { value: EnumOfferCategory.StuffMoving, label: "Umzug" },
        {
          value: EnumOfferCategory.PeopleTransport,
          label: "Personentransport",
        },
        {
          value: EnumOfferCategory.MovingTransportationOther,
          label: "Andere Umzug und Transport",
        },
      ],
    },
    {
      label: "Renovierung",
      options: [
        { value: EnumOfferCategory.HouseRenovation, label: "Hausrenovierung" },
        {
          value: EnumOfferCategory.ApartmentRenovation,
          label: "Wohnungsrenovierung",
        },
        { value: EnumOfferCategory.OfficeRenovation, label: "Bürorenovierung" },
        {
          value: EnumOfferCategory.RenovationOther,
          label: "Andere Renovierung",
        },
      ],
    },
    {
      label: "Schönheit",
      options: [
        { value: EnumOfferCategory.Makeup, label: "Makeup" },
        {
          value: EnumOfferCategory.ManicurePedicure,
          label: "Maniküre und Pediküre",
        },
        { value: EnumOfferCategory.BeautyOther, label: "Andere Schönheit" },
      ],
    },
    {
      label: "Nachhilfe",
      options: [
        {
          value: EnumOfferCategory.Tutoring,
          label: "Nachhilfe und Lernbetreuung",
        },
      ],
    },
    {
      label: "Andere",
      options: [{ value: EnumOfferCategory.Other, label: "Andere Nachhilfe" }],
    },
  ],
};

// First, define the interface for the options within each category
interface CategoryOption {
  value: EnumOfferCategory;
  label: string;
}

// Then, define the interface for each category, which includes a label and an array of options
export interface ServiceCategory {
  label: string;
  options: CategoryOption[];
}

export interface CategoriesResourcesInterface {
  categories: ServiceCategory[];
}
