// constants
import { IAuthActionType, IUserData } from "../interfaces";

import { AuthActionTypes } from "./constants";

// common success
export const authApiResponseSuccess = (
  actionType: string,
  data: IUserData | {}
): IAuthActionType => ({
  type: AuthActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// common error
export const authApiResponseError = (
  actionType: string,
  error: string
): IAuthActionType => ({
  type: AuthActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const loginUser = (user: IUserData): IAuthActionType => ({
  type: AuthActionTypes.LOGIN_USER,
  payload: { user: user },
});

export const logoutUser = (): IAuthActionType => ({
  type: AuthActionTypes.LOGOUT_USER,
  payload: {},
});

export const signupUser = (
  fullName: string,
  email: string,
  password: string
): IAuthActionType => ({
  type: AuthActionTypes.SIGNUP_USER,
  payload: { fullName, email, password },
});

export const forgotPassword = (email: string): IAuthActionType => ({
  type: AuthActionTypes.FORGOT_PASSWORD,
  payload: { email },
});

export const resetAuth = (): IAuthActionType => ({
  type: AuthActionTypes.RESET,
  payload: {},
});
