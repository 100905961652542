import "react-datepicker/dist/react-datepicker.css";
import "./i18n/i18n";

import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  createHttpLink,
} from "@apollo/client";

import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import ReactDOM from "react-dom/client";
import { config } from "./config/config";
import { configureStore } from "./redux/store";
import { setContext } from "@apollo/client/link/context";

const httpLink = createHttpLink({
  uri: config.backendUrl,
});

const authLink = setContext((_, { headers }) => {
  // const token = process.env.REACT_APP_FRONTEND_SECRET_TOKEN;
  // TODO: Hide token
  const token = "my_secret_token";
  return {
    headers: {
      ...headers,
      "X-Frontend-Token": token ? token : "",
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

const root = ReactDOM.createRoot(document.getElementById("root")!);
root.render(
  <ApolloProvider client={client}>
    <Provider store={configureStore({})}>
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <App />
      </BrowserRouter>
    </Provider>
  </ApolloProvider>
);
