import { EnumSubscriptionType } from "../../redux/interfaces";

export const PricingResourcesEN: IPricingResources = {
  title: "PLANS AND PRICES",
  subtitle: "Choose your plan and boost your business.",
  plans: [
    {
      id: 1,
      name: "TASK HUNT",
      price: 49,
      duration: "license",
      description: () => (
        <p>
          Browse tasks unlimited and receive a notification for each new task in
          your categories.
        </p>
      ),
      features: [
        {
          type: EnumSubscriptionType.BASIC1,
          feature: () => (
            <>
              1 month: <b>EUR 59,99</b>
            </>
          ),
        },
        {
          type: EnumSubscriptionType.BASIC3,
          feature: () => (
            <>
              3 months: <b>EUR 54,99 /month</b>
            </>
          ),
        },
        {
          type: EnumSubscriptionType.BASIC6,
          feature: () => (
            <>
              6 months: <b>EUR 49,99 /month</b>
            </>
          ),
        },
        {
          type: EnumSubscriptionType.BASIC12,
          feature: () => (
            <>
              12 months: <b>EUR 39,99 /month</b>
            </>
          ),
        },
      ],
      isRecommended: false,
    },
    {
      id: 2,
      name: "SERVI.CO-BOOK",
      price: 149,
      duration: "license",
      description: () => (
        <p>
          Register yourself in the servi.co phonebook and increase your
          visibility.
        </p>
        // Some customers prefer to contact service providers
        // directly.
      ),
      features: [
        {
          type: EnumSubscriptionType.PHONEBOOK1,
          feature: () => (
            <>
              1 month: <b>EUR 29,99</b>
            </>
          ),
        },
        {
          type: EnumSubscriptionType.PHONEBOOK3,
          feature: () => (
            <>
              3 months: <b>EUR 24,99 /month</b>
            </>
          ),
        },
        {
          type: EnumSubscriptionType.PHONEBOOK6,
          feature: () => (
            <>
              6 months: <b>EUR 19,99 /month</b>
            </>
          ),
        },
        {
          type: EnumSubscriptionType.PHONEBOOK12,
          feature: () => (
            <>
              12 months: <b>EUR 9,99 /month</b>
            </>
          ),
        },
      ],
      isRecommended: false,
    },
    {
      id: 3,
      name: "BUNDLE",
      price: 999,
      duration: "license",
      description: () => (
        <p>
          Use all the benefits of TASK HUNT and SERVI.CO-BOOK combined, at the
          premium price.
        </p>
      ),
      features: [
        {
          type: EnumSubscriptionType.BUNDLE1,
          feature: () => (
            <>
              1 month: <b>EUR 79,99</b>
            </>
          ),
        },
        {
          type: EnumSubscriptionType.BUNDLE3,
          feature: () => (
            <>
              3 months: <b>EUR 69,99 /month</b>
            </>
          ),
        },
        {
          type: EnumSubscriptionType.BUNDLE6,
          feature: () => (
            <>
              6 months: <b>EUR 59,99 /month</b>
            </>
          ),
        },
        {
          type: EnumSubscriptionType.BUNDLE12,
          feature: () => (
            <>
              12 months: <b>EUR 49,99 /month</b>
            </>
          ),
        },
      ],
      isRecommended: true,
    },
  ],
};

export const PricingResourcesDE: IPricingResources = {
  title: "PLÄNE UND PREISE",
  subtitle: "Wählen Sie Ihren Plan und ankurbeln Sie Ihr Geschäft.",
  plans: [
    {
      id: 1,
      name: "TASK HUNT",
      price: 49,
      duration: "license",
      description: () => (
        <p>
          Durchsuchen Sie Aufgaben unbegrenzt und erhalten Sie eine
          Benachrichtigung für jede neue Aufgabe in Ihren Kategorien.
        </p>
      ),
      features: [
        {
          type: EnumSubscriptionType.BASIC1,
          feature: () => (
            <>
              1 Monat: <b>EUR 59,99</b>
            </>
          ),
        },
        {
          type: EnumSubscriptionType.BASIC3,
          feature: () => (
            <>
              3 Monate: <b>EUR 54,99 /Monat</b>
            </>
          ),
        },
        {
          type: EnumSubscriptionType.BASIC6,
          feature: () => (
            <>
              6 Monate: <b>EUR 49,99 /Monat</b>
            </>
          ),
        },
        {
          type: EnumSubscriptionType.BASIC12,
          feature: () => (
            <>
              12 Monate: <b>EUR 39,99 /Monat</b>
            </>
          ),
        },
      ],
      isRecommended: false,
    },
    {
      id: 2,
      name: "SERVI.CO-BOOK",
      price: 149,
      duration: "license",
      description: () => (
        <p>
          Tragen Sie sich in das servi.co Phonebook ein und erhöhen Sie Ihre
          Sichtbarkeit.
        </p>
        //  Manche Kunden ziehen es vor, Dienstanbieter direkt zu kontaktieren.
      ),
      features: [
        {
          type: EnumSubscriptionType.PHONEBOOK1,
          feature: () => (
            <>
              1 Monat: <b>EUR 29,99</b>
            </>
          ),
        },
        {
          type: EnumSubscriptionType.PHONEBOOK3,
          feature: () => (
            <>
              3 Monate: <b>EUR 24,99 /Monat</b>
            </>
          ),
        },
        {
          type: EnumSubscriptionType.PHONEBOOK6,
          feature: () => (
            <>
              6 Monate: <b>EUR 19,99 /Monat</b>
            </>
          ),
        },
        {
          type: EnumSubscriptionType.PHONEBOOK12,
          feature: () => (
            <>
              12 Monate: <b>EUR 9,99 /Monat</b>
            </>
          ),
        },
      ],
      isRecommended: false,
    },
    {
      id: 3,
      name: "BUNDLE",
      price: 999,
      duration: "license",
      description: () => (
        <p>
          Nutzen Sie alle Vorteile von TASK HUNT und SERVI.CO-BOOK kombiniert
          zum Premium Preis.
        </p>
      ),
      features: [
        {
          type: EnumSubscriptionType.BUNDLE1,
          feature: () => (
            <>
              1 Monat: <b>EUR 79,99</b>
            </>
          ),
        },
        {
          type: EnumSubscriptionType.BUNDLE3,
          feature: () => (
            <>
              3 Monate: <b>EUR 69,99 /Monat</b>
            </>
          ),
        },
        {
          type: EnumSubscriptionType.BUNDLE6,
          feature: () => (
            <>
              6 Monate: <b>EUR 59,99 /Monat</b>
            </>
          ),
        },
        {
          type: EnumSubscriptionType.BUNDLE12,
          feature: () => (
            <>
              12 Monate: <b>EUR 49,99 /Monat</b>
            </>
          ),
        },
      ],
      isRecommended: true,
    },
  ],
};
export interface IPricingResources {
  title: string;
  subtitle: string;
  plans: PlanItemsTypes[];
}

interface IFeatureItem {
  feature: () => React.ReactNode;
  type: EnumSubscriptionType;
}

export interface PlanItemsTypes {
  id: number;
  name: string;
  price: number;
  duration: string;
  features: IFeatureItem[];
  isRecommended: boolean;
  description: () => React.ReactNode;
}
