export const Error404ResourcesEN: IError404Resources = {
  title:
    "It seems you've found our website's hidden corner. There's not much here, but back on the homepage, we have plenty to offer!",
  subtitle: () => (
    <>
      Search tips:
      <br />
      Try similar but different search selection
      <br />
      Try looking by filtering within the categories
    </>
  ),
  button: "Back to Home page",
};

export const Error404ResourcesDE: IError404Resources = {
  title:
    "Anscheinend haben Sie die versteckte Ecke unserer Website gefunden. Hier gibt es nicht viel, aber zurück auf der Homepage haben wir viel zu bieten!",
  subtitle: () => (
    <>
      Search tips:
      <br />
      Try similar but different search selection
      <br />
      Try looking by filtering within the categories
    </>
  ),
  button: "Zurück zur Startseite",
};

export interface IError404Resources {
  title: string;
  subtitle: () => void; // Use React.ReactNode if you expect any kind of React node
  button: string;
}
