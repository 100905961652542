import "./assets/scss/Default.scss";
import "./assets/scss/Landing.scss";
import "./assets/scss/Icons.scss";

import AllRoutes from "./routes/Routes";
import GlobalStyle from "./style/GlobalStyle";
import { ThemeProvider } from "styled-components";
import { theme } from "./style/theme";

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <AllRoutes />
    </ThemeProvider>
  );
};

export default App;
